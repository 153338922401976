import React, { FunctionComponent } from "react";
import loadable from "@loadable/component";
const PopularProducts = loadable(() => import("../PopularProducts"));
const ProductsList = loadable(() => import("../ProductsList"));
const Linktree = loadable(() => import("../Linktree"));
const Tweet = loadable(() => import("../Tweet"));
const HeroSkrollr = loadable(() => import("../HeroSkrollr"));
const VideoTeaser = loadable(() => import("../VideoTeaser"));
const CtaBlock = loadable(() => import("../CtaBlock"));
const SocialWall = loadable(() => import("../SocialWall"));
const TickerText = loadable(() => import("../TickerText"));
const PromoBlock = loadable(() => import("../PromoBlock"));
const SectionTeaser = loadable(() => import("../SectionTeaser"));
const ArticleDoubleTeaser = loadable(() => import("../ArticleDoubleTeaser"));
const ArticleListing = loadable(() => import("../ArticleListing"));
const PlaylistSlider = loadable(() => import("../PlaylistSlider"));
const GenericSlider = loadable(() => import("../GenericSlider"));
const VideoSlider = loadable(() => import("../VideoSlider"));
const ProductSlider = loadable(() => import("../ProductSlider"));
const ProductSliderV2 = loadable(() => import("../ProductSliderV2"));
const ProductSliderV3 = loadable(() => import("../ProductSliderV3"));
const ProductSliderV4 = loadable(() => import("../ProductSliderV4"));
const ArticleSlider = loadable(() => import("../ArticleSlider"));
const ArticleByCategorySlider = loadable(() => import("../ArticleByCategorySlider"));
const FeaturePromoBlock = loadable(() => import("../FeaturePromoBlock"));
const FeaturePromoDouble = loadable(() => import("../FeaturePromoDouble"));
const ArticleSingleTeaser = loadable(() => import("../ArticleSingleTeaser"));
const IframePromotion = loadable(() => import("../IframePromotion"));
const ChildPageTeaser = loadable(() => import("../ChildPageTeaser"));
const TrainingVideoBlock = loadable(() => import("../TrainingVideoBlock"));
const ImageTextBlock = loadable(() => import("../ImageTextBlock"));
const ZoovuQuiz = loadable(() => import("../ZoovuQuiz"));
const PromotigoIframe = loadable(() => import("../PromotigoIframe"));
const VideoBlockExperimental = loadable(() => import("../VideoBlockExperimental"));
const TeaserBlock = loadable(() => import("../TeaserBlockExperimental"));
const Carousel = loadable(() => import("../carousel"));
const PromoBlockExperimental = loadable(() => import("../PromoBlockExperimental"));
const Grid = loadable(() => import("../Grid"));
const TextBlockExperimental = loadable(() => import("../TextBlockExperimental"));
const TextBlockV2 = loadable(() => import("../TextBlockV2"));
const TextBlockV3 = loadable(() => import("../TextBlockV3"));
const PromoBlockV2 = loadable(() => import("../PromoBlockV2"));
const PromoBlockV3 = loadable(() => import("../PromoBlockV3"));
const FeaturedVideo = loadable(() => import("../featuredVideo"));
const SimpleHero = loadable(() => import("../SimpleHero"));
const HeroV2 = loadable(() => import("../HeroV2"));
const HeroV3 = loadable(() => import("../HeroV3"));
const HeroV4 = loadable(() => import("../HeroV4"));
const FAQBlock = loadable(() => import("../FAQBlock"));
const ImageGallery = loadable(() => import("../ImageGallery"));
const TextBlocksGrid = loadable(() => import("../TextBlocksGrid"));
const ProductLandingTeaser = loadable(() => import("../ProductLandingTeaser"));
const SloganTeaser = loadable(() => import("../SloganTeaser"));
const Accordion = loadable(() => import("../Accordion"));
const BluePrintSlider = loadable(() => import("../BluePrintSlider"));
const YoutubeVideoBlock = loadable(() => import("../YoutubeVideoBlock"));
const TwoColumnsStickyScroll = loadable(() => import("../TwoColumnsStickyScroll"));
const FeaturedCarousel = loadable(() => import("../FeaturedCarousel"));
const Promotigo = loadable(() => import("../../templates/Promotigo"));
const NewsletterSignUpFormBlock = loadable(() => import("../NewsletterSignUpFormBlock"));
const AnchorElement = loadable(() => import("../AnchorElement"));
const IframeSnippetPromotion = loadable(() => import("../IframeSnippetPromotion"));
const ProductLandingHero = loadable(() => import("../ProductLandingHero"));
const StoreLocator = loadable(() => import("../StoreLocator"));

type BlockRenderableSanityTypeNameToComponentMap = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [typeName: string]: FunctionComponent<any>;
};

const componentsMap: BlockRenderableSanityTypeNameToComponentMap = {
  SanityVideoTeaser: VideoTeaser,
  SanityCtaBlock: CtaBlock,
  SanityMiappi: SocialWall,
  SanityTickerText: TickerText,
  SanityPromoBlock: PromoBlock,
  SanitySectionTeaserBlock: SectionTeaser,
  SanityArticleSingleTeaserBlock: ArticleSingleTeaser,
  SanityArticleDoubleTeaserBlock: ArticleDoubleTeaser,
  SanityArticleListing: ArticleListing,
  SanityPlaylistSlider: PlaylistSlider,
  SanityGenericSlider: GenericSlider,
  SanityVideoSlider: VideoSlider,
  SanityProductSlider: ProductSlider,
  SanityProductSliderV2: ProductSliderV2,
  SanityProductSliderV3: ProductSliderV3,
  SanityArticleSlider: ArticleSlider,
  SanityArticleByCategorySlider: ArticleByCategorySlider,
  SanityFeaturePromoBlock: FeaturePromoBlock,
  SanityFeaturePromoDouble: FeaturePromoDouble,
  SanityIframePromotion: IframePromotion,
  SanityZoovuQuiz: ZoovuQuiz,
  SanityPromotigoIframe: PromotigoIframe,
  SanityPromotigo: ChildPageTeaser,
  SanityProductLinePage: ChildPageTeaser,
  SanitySubSectionPage: ChildPageTeaser,
  SanityTrainingVideo: TrainingVideoBlock,
  SanityImageTitleBlock: ImageTextBlock,
  SanityNewsletterSignUpFormBlock: NewsletterSignUpFormBlock,
  SanityStoreLocator: StoreLocator,
  newsletterSignUpFormBlock: NewsletterSignUpFormBlock,
  imageTitleBlock: ImageTextBlock,
  imageBlock: ImageTextBlock,
  miappi: SocialWall,
  tickerText: TickerText,
  featurePromoBlock: FeaturePromoBlock,
  articleSingleTeaserBlock: ArticleSingleTeaser,
  articleDoubleTeaserBlock: ArticleDoubleTeaser,
  articleListing: ArticleListing,
  sectionTeaserBlock: SectionTeaser,
  featurePromoDouble: FeaturePromoDouble,
  promoBlock: PromoBlock,
  playlistSlider: PlaylistSlider,
  genericSlider: GenericSlider,
  videoSlider: VideoSlider,
  articleSlider: ArticleSlider,
  articleByCategorySlider: ArticleByCategorySlider,
  productSlider: ProductSlider,
  productSliderV2: ProductSliderV2,
  productSliderV4: ProductSliderV4,
  ctaBlock: CtaBlock,
  productLinePage: ChildPageTeaser,
  subSectionPage: ChildPageTeaser,
  promotigo: ChildPageTeaser,
  trainingVideo: TrainingVideoBlock,
  videoTeaser: VideoTeaser,
  iframePromotion: IframePromotion,
  promotigoIframe: PromotigoIframe,
  zoovuQuiz: ZoovuQuiz,
  SanityTeaserBlockExperimental: TeaserBlock,
  SanityVideoBlockExperimental: VideoBlockExperimental,
  SanityPromoBlockExperimental: PromoBlockExperimental,
  SanityCarousel: Carousel,
  SanityProductRangeTeaser: ProductLandingTeaser,
  SanityTweetNow: Tweet,
  SanityGrid: Grid,
  SanityTextBlockExperimental: TextBlockExperimental,
  SanityTextBlockV2: TextBlockV2,
  SanityTextBlockV3: TextBlockV3,
  SanityPromoBlockV2: PromoBlockV2,
  SanityPromoBlockV3: PromoBlockV3,
  SanityFeaturedVideo: FeaturedVideo,
  SanitySimpleHero: SimpleHero,
  SanityHerov2: HeroV2,
  SanityHerov3: HeroV3,
  SanityHerov4: HeroV4,
  SanityFrequentlyAskedQuestions: FAQBlock,
  SanityImageGallery: ImageGallery,
  SanityTextBlockGrid: TextBlocksGrid,
  SanitySloganTeaser: SloganTeaser,
  SanityAccordion: Accordion,
  SanityHeroSkrollr: HeroSkrollr,
  SanityBluePrintSlider: BluePrintSlider,
  SanityYoutubeBlock: YoutubeVideoBlock,
  SanityTwoCloumnStickyScroll: TwoColumnsStickyScroll,
  SanityLinkTree: Linktree,
  SanityPopularProducts: PopularProducts,
  SanityProductsList: ProductsList,
  featuredCarousel: FeaturedCarousel,
  SanityAnchorElement: AnchorElement,
  SanityIframeSnippet: IframeSnippetPromotion,
  productHero: ProductLandingHero,
};

export interface BlockRendererInterface {
  section: {
    __typename?: string;
    _type?: string;
  };
  index?: number;
}

const BlockRenderer: FunctionComponent<BlockRendererInterface> = ({ section, index }) => {
  const sanityType = (section?.__typename ? section.__typename : section?._type) as string;
  const getComponent = (sanityType: string) => {
    const component = componentsMap[sanityType];
    if (component) {
      return component;
    } else {
      console.error(`Unknown block type: ${sanityType}`);
      return null;
    }
  };

  const Comp = getComponent(sanityType);

  return Comp
    ? React.createElement(Comp, {
        ...section,
        ...{ index }
      })
    : null;
};

export default BlockRenderer;
